import React from "react";
import { Link } from 'gatsby';

import SEO from "../components/seo";

import './../scss/main.scss';

const NotFoundPage = () => (
  <>
    <SEO title="Not found | GSoftware" />
    <div className="container py-4 px-3">
      <h1 className="text-center mb-3 display-1">404</h1>
      <h4 className="text-center display-4">Page not found</h4>
      <p className="text-center">Page you were looking for doesn't exist... Go back to <Link to="/">homepage</Link>.</p>
    </div>
  </>
);

export default NotFoundPage;
